@keyframes lightUp {
  0% {
    fill: #3e8e41;
  }

  100.0% {
    fill: #3e8e41;
  }
}

@keyframes pulse {
  0% {
    fill: #3e8e41;
  }

  100.0% {
    fill: #fff;
  }
}

@keyframes gummyPulse {
  0% {
    fill: #3a3a3a;
  }

  100.0% {
    fill: #d04033;
  }
}

.button:hover {
  cursor: pointer;
}

.button:active > text {
  animation: lightUp 2s forwards linear;
  transform: translateY(1px);
}

.button:active > g > mask {
  animation: lightUp 2s forwards linear;
  transform: translateY(1px);
}

.button:active {
  animation: lightUp 2s forwards linear;
  transform: translateY(1px);
}

.button {
  animation: lightUp 2s forwards linear;
}

.boot-button > text {
  animation: pulse 0.75s infinite ease-out;
}

.boot-button-gummy > g + g > path {
  animation: gummyPulse 0.75s infinite ease-out;
}

.boot-button-gummy > g + g > g > path {
  animation: gummyPulse 0.75s infinite ease-out;
}

.html2canvas-container {
  height: 100vh;
}
